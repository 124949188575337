@import "src/global/styles/colors";

.Input {
  &_root {
    position: relative;
    width: 100%;
  }

  &_label {
    color: $color_gray_dark;
    font-family: 'SF-Bold', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    display: block;
    margin: 0 0 6px 4px;
  }

  &_component {
    width: 100%;
    background: $color_white;
    border-radius: 8px;
    font-feature-settings: 'case' on;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    box-sizing: border-box;
    padding: 12px 16px;
    border: 1px solid transparent;

    &::placeholder {
      color: $color_graywash_900;
    }

    &:hover {
      border: 1px solid rgba(68, 26, 84, 0.24);
    }

    &:focus {
      border: 1px solid $color_purple_light;
    }
  }

  &_withError {
    border: 1px solid $color_red;
  }

  &_icon {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 12px;
    top: 12px
  }

  &_error {
    color: $color_red;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}