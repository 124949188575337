.container {
  div {
    border: 2px solid #0F1B24;
    width: 18px;
    height: 18px;
    border-radius: 4px;
  }

  label {
    width: fit-content;
    display: flex;
    gap: 10px;
    align-items: center;
    color: #0F1B24;
    font-family: "Open Sans", serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
