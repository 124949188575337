@import "src/global/styles/colors";
@import "src/global/styles/scroll";

.Select {
  &_root {
    position: relative;
    width: 100%;
  }

  &_arrow {
    position: absolute;
    top: 15px;
    right: 15px;
    transition: all .2s;
  }

  &_open {
    transform: rotate(180deg);
  }

  &_label {
    color: $color_gray_dark;
    font-family: 'SF-Bold', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    display: block;
    margin: 0 0 6px 4px;
  }

  &_component {
    width: 100%;
    min-width: 196px;
    height: 100%;
    max-height: 36px;
    background: $color_white;
    border-radius: 0.125rem;
    font-feature-settings: 'case' on;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    box-sizing: border-box;
    padding: 7px 16px;
    border: 1px solid transparent;
    cursor: pointer;
    user-select: none;
    position: relative;

    &::placeholder {
      color: $color_graywash_900;
    }

    &:hover {
      border: 1px solid rgba(68, 26, 84, 0.24);
    }

    &:focus {
      border: 1px solid $color_purple_light;
    }
  }

  &_gray {
    background: $color_gray_light;
  }

  &_withShadow {
    box-shadow: 0 4px 8px 0 #E5E7E9;
  }

  &_dropDown {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 12px 0;
    background: $color_white;
    border-radius: 8px;
    transform: translateY(calc(100% + 6px));
    z-index: 3;
    box-shadow: 0 4px 8px 0 #E5E7E9;
    overflow: auto;
    max-height: 300px;

    @include scroll();

    &__item {
      box-sizing: border-box;
      padding: 4px 16px;
      cursor: pointer;
      transition: all .2s;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &:hover {
        background: #F1F2F3;
      }
    }
  }

  &_name {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
  }
}