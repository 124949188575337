@import "src/global/styles/colors";

.PageWrapper {
  &_root {
    min-height: 100vh;
    background: $color_gray_light;
  }

  &_content {
    @media all and (min-width: 1440px) {
      width: 1440px;
    }

    margin: 0 auto;
    min-height: calc(100vh - 191px);
    box-sizing: border-box;
    padding: 0 80px;
  }

  &_adminContent {
    position: relative;
    padding-left: 20px;
    padding-right: 40px;
    width: 100%;
  }

  &_contentFull {
    width: 100%;
    padding: 0;
  }

  &_adminContainer {
    display: flex;
    min-height: inherit;
  }

  &_adminHeader {
    box-shadow: 0 4px 8px 0 rgb(229, 231, 233);
    background: rgb(255, 255, 255);
    max-width: 277px;
    padding: 16px 0 0 0;
    width: 100%;
  }
}
