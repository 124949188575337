@mixin scroll() {
  &::-webkit-scrollbar {
    height: 0.25rem;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: rgba(15, 27, 36, 0.32);
  }
}
