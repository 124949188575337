.img {
  object-fit: cover;
  width: 100%;
  position: relative;
  z-index: 2;
  height: 100%;
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto 0;
  width: 100%;
  height: 100vh;
  border: 32px solid white;
  background: white;
}

.icon {
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 3;
}
