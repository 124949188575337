@import "src/global/styles/colors";

.Footer {
  &_root {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 76px;
    margin: 0 auto;
    border-top: 1px solid $color_graywash_800;
    padding: 0 80px;

    @media all and (min-width: 1288px){
      width: 1288px;
    }
  }

  &_name {
    color: $color_graywash_700;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    user-select: none;
  }

  &_links {
    display: flex;

    & a {
      color: $color_purple_light;
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin: 0 0 0 32px;
      transition: all .2s;
      user-select: none;

      &:hover {
        color: $color_purple_dark;
      }
    }
  }
}