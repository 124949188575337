@import "src/global/styles/colors";

.icon {
  cursor: pointer;

  path {
    fill: #848F98;
  }

  &:hover {
    path {
      fill: $color_purple_light;
    }
  }
}
