@import "../../global/styles/scroll";

.button {
  display: flex;
  align-items: center;
  gap: 13px;
  padding: 9px 20px;
}

.addUser {
  border-radius: 24px;
  background: rgb(241, 242, 243);

  &_search {
    padding-top: 12px;
  }

  &_checkboxes {
    display: flex;
    flex-direction: column;
    padding-top: 16px;

    & > div {
      border-bottom: 1px solid rgba(132, 143, 152, 0.16);
      padding: 7px 0 7px 0;
    }
  }

  &_searchLabel {
    color: #0F1B24;
    font-family: "Open Sans", serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    padding-bottom: 12px;
  }

  & > h3 {
    text-align: center;
    color: rgb(15, 27, 36);
    font-family: Open Sans, serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }

  & > p {
    padding-top: 12px;
    text-align: center;
    color: rgba(15, 27, 36, 0.72);
    font-family: Open Sans, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  & > form {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &_actions {
    display: flex;
    padding-top: 20px;
    gap: 8px;
  }

  &_actions_container {
    display: flex;
    gap: 8px;
  }

  &_actions_cancel {
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.16);
    color: rgb(15, 27, 36);

    &:hover {
      background: transparent;
    }
  }

  &_actions_btn {
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.16);

    svg > path {
      fill: rgb(71, 85, 105);
    }

    &:hover {
      background: transparent;
    }
  }

  &_removeToManager {
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.16);
    color: rgb(15, 27, 36);
    margin-top: 16px;
    width: 100%;

    &:hover {
      background: transparent;
    }
  }

  &_aboutContainer {
    padding-top: 20px;
    max-height: 313px;
    overflow-y: auto;
    @include scroll();
  }

  &_aboutItem {
    display: flex;
    flex-direction: column;
    padding: 8px 0 8px 0;
    border-top: 1px solid rgba(132, 143, 152, 0.16);
    gap: 4px;

    &:last-child {
      border-bottom: 1px solid rgba(132, 143, 152, 0.16);
    }
  }

  &_aboutItemLabel {
    color: rgba(15, 27, 36, 0.64);
    font-family: SFProText-Regular, serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;

    & > span {
      color: rgb(15, 27, 36);
      font-family: SFProText-Regular, serif;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
    }
  }

  &_aboutItemValue {
    color: rgb(15, 27, 36);
    font-family: Open Sans, serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  }

  &_unloadBtn {
    margin-top: 16px;
  }
}

.colors {
  display: flex;
  padding-top: 16px;
  gap: 4px;
  flex-wrap: wrap;
}

.color {
  padding: 8px;
  height: 108px;
  width: 108px;
  position: relative;

  &_info {
    display: flex;
    flex-direction: column;
    color: #FFF;
    font-family: SFProText-Regular, serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  svg {
    position: absolute;
    bottom: 8px;
    left: 8px;
  }
}

.auth {
  background: #F1F2F3;
  padding: 16px 24px 0 24px;

  &_title {
    color: #0F1B24;
    font-family: "Open Sans", serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    text-align: center;
    padding-top: 121px;
  }

  &_subTitle {
    color: rgba(15, 27, 36, 0.64);
    text-align: center;
    font-family: SFProText-Regular, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding-top: 12px;
    max-width: 465px;
    width: 100%;
    margin: 0 auto;
  }

  &_form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 40px;
    min-height: calc(100vh - 301px);
    margin:  0 auto;
    max-width: 382px;
    width: 100%;

    button {
      margin: 0 auto;
    }
  }
}

.actionBtn {
  display: flex;
  margin: 0 auto;
}

.hide {
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.16);

  svg > path {
    fill: rgb(71, 85, 105);
  }

  &:hover {
    background: transparent;
  }
}

.seasons {
  &_nameContainer {
    display: flex;
    flex-direction: column;
    gap: 6px;
    border-top: 1px solid rgba(132, 143, 152, 0.16);
    border-bottom: 1px solid rgba(132, 143, 152, 0.16);
    padding: 8px 0 12px 0;
    margin-top: 20px;
  }

  &_nameLabel {
    color: rgba(15, 27, 36, 0.64);
    font-family: "SFProText-Regular";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 133.333% */
  }

  &_name {
    color: #0F1B24;
    font-feature-settings: 'case' on;
    font-family: "SFProText-Regular";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &_productsContainer {
    padding-top: 12px;

    & > p {
      color: #0F1B24;
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px; /* 157.143% */
      padding-bottom: 8px;
    }
  }

  &_products {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    margin-top: 16px;
    overflow-y: auto;
    max-height: 268px;
    @include scroll();
  }

  &_product {
    height: 108px;
    width: 108px;
    color: #0F1B24;
    font-family: "Open Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 4px 8px 0 8px;
    position: relative;
  }

  &_productLight {
    color: #fff;
  }

  &_productEffect {
    position: absolute;
    left: 0;
    top: 0;
    height: 108px;
    width: 108px;
  }
}
