.container {
  background: white;
}

.eyeDropperIcon {
  cursor: url(../../assets/iconsEditor/dropper.svg) 0 16, auto;
}

.zoomInIcon {
  cursor: url(../../assets/iconsBtn/zoomIn.svg) 0 16, auto;
}

.zoomOutIcon {
  cursor: url(../../assets/iconsBtn/zoomOut.svg) 0 16, auto;
}

.fillFloodIcon {
  cursor: url(../../assets/iconsEditor/paint.svg) 16 16, auto;
}
