.logout {
  cursor: pointer;
}

.actions {
  display: flex;
  padding-top: 20px;
  gap: 8px;
}

.actions_container {
  display: flex;
  gap: 8px;
}

.removeToManager {
   background: transparent;
   border: 1px solid rgba(0, 0, 0, 0.16);
   color: rgb(15, 27, 36);
   margin-top: 16px;
   width: 100%;

   &:hover {
     background: transparent;
   }
 }

.actions_btn {
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.16);

  svg > path {
    fill: rgb(71, 85, 105);
  }

  &:hover {
    background: transparent;
  }
}

.cancel {
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.16);
  color: rgb(15, 27, 36);

  &:hover {
    background: transparent;
  }
}

.select {
  & div[class*='react-select__control'] {
    height: 44px;
  }
}

.aboutContainer {
  padding-top: 20px;
  max-height: 313px;
  overflow-y: auto;
}

.aboutItem {
  display: flex;
  flex-direction: column;
  padding: 8px 0 8px 0;
  border-top: 1px solid rgba(132, 143, 152, 0.16);
  gap: 4px;
}

.aboutItemLabel {
  color: rgba(15, 27, 36, 0.64);
  font-family: SFProText-Regular, serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;

  & > span {
    color: rgb(15, 27, 36);
    font-family: SFProText-Regular, serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
  }
}

.aboutItemValue {
  color: rgb(15, 27, 36);
  font-family: Open Sans, serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}

.search {
  padding-top: 12px;
}

.subTitle {
  color: rgba(15, 27, 36, 0.64);
  text-align: center;
  font-family: SFProText-Regular, serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
}

.button {
  display: flex;
  align-items: center;
  gap: 13px;
  padding: 9px 20px;
}
