.fillFloodIcon {
  cursor: url(../../assets/iconsEditor/paint.svg) 16 16, auto;
}

.brushIcon {
  cursor: url(../../assets/iconsEditor/brush.svg) 0 16, auto;
}

.eraserIcon {
  cursor: url(../../assets/iconsEditor/eraser.svg) 0 16, auto;
}

.zoomInIcon {
  cursor: url(../../assets/iconsBtn/zoomIn.svg) 0 16, auto;
}

.zoomOutIcon {
  cursor: url(../../assets/iconsBtn/zoomOut.svg) 0 16, auto;
}

.container {
  background: white;
}
