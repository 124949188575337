$color_black: #000;
$color_white: #fff;
$color_red: #DA0D0D;

$color_gray_dark: #0F1B24;
$color_gray_middle: #848F98;
$color_gray_light: #F1F2F3;

$color_purple_dark: #300D3E;
$color_purple_light: #441A54;

$color_graywash_1000: rgba(15, 27, 36, 0.64);
$color_graywash_900: rgba(15, 27, 36, 0.48);
$color_graywash_800: rgba(132, 143, 152, 0.16);
$color_graywash_700: rgba(71, 82, 90, 0.88);


