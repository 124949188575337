@import "src/global/styles/scroll";

.table {
  border: 0 none;
  border-collapse: separate;
  border-spacing: 0 8px;
  width: 100%;
  white-space: nowrap;

  &_tbody {
    padding-top: 20px;
  }

  &_th {
    height: 52px;
    color: rgb(15, 27, 36);
    font-family: Open Sans, serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
  }

  &_tr {
    background: rgb(255, 255, 255);
    height: 52px;
  }

  &_td {
    border: 0 transparent solid;
    color: rgb(15, 27, 36);
    font-family: Open Sans, serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    white-space: break-spaces;
    padding: 14px 0 14px 0;
    vertical-align: baseline;
    overflow: auto;

    @include scroll();

    &:first-child {
      border-left-style: solid;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }

  td:last-child {
    border: 0 transparent solid;
    border-right-style: solid;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
  }

  &td_id {
    color: rgb(68, 26, 84);
    font-family: Open Sans, serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    width: fit-content;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background: rgba(68, 26, 84, 0.1);
    margin: 0 auto;
    padding: 0 7px;
  }

  &_btn {
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.16);

    svg > path {
      fill: rgb(71, 85, 105);
    }

    &:hover {
      background: transparent;
    }
  }

  &_paginate {
    align-items: center;
    display: flex;
    gap: 8px;
    padding-top: 16px;
    padding-bottom: 40px;
  }

  &_paginatePage, &_paginateBreak {
    border-radius: 8px;
    border: 1px solid #D8DEEE;
    cursor: pointer;
    color: #0F1B24;
    text-align: center;
    font-family: "Open Sans", serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    a {
      display: block;
      padding: 8px 11px;
    }
  }

  &_paginateActive {
    border-radius: 8px;
    background: #441A54;
    color: #FFF;
  }
}

.container {
  position: relative;
  min-height: 736px;
}

.icons {
  display: flex;
  gap: 12px;
  padding-right: 12px;
  justify-content: flex-end;
}

.deletedField {
  background: #dcdcdc;
}
