.container {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}

.season {
  width: 138px;
  height: 138px;
  color: #0F1B24;
  font-family: "Open Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 8px;
  position: relative;

  &_icon {
    position: absolute;
    left: 8px;
    bottom: 8px;
    z-index: 2;
    cursor: pointer;
  }

  &_effect {
    position: absolute;
    width: 100%;
    z-index: 1;
    border: 0 none;
    padding: 0;
    object-fit: cover;
    height: 138px;
    top: 0;
    left: 0;
  }
}

.header {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 24px;

  &_select {
    max-width: 200px;
  }
}

.emptyColors {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: calc(100vh - 350px);
  justify-content: center;

  p {
    color: rgba(71, 82, 90, 0.88);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
  }
}
