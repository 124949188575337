@import "src/global/styles/colors";
@import "src/global/styles/scroll";

.ActionPanel {
  max-width: 300px;
  width: 100%;
  background: $color-white;
  height: auto;
  box-sizing: border-box;
  padding: 16px;
  position: relative;
  border-right: 1px solid #F1F2F3;
}

.Select {
  margin: 0 0 8px 0;
}

.Input {
  & > input {
    background: $color_gray_light;
  }
}

.SelectedColors {
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);
  justify-content: space-between;

  &__dropperContainer {
    display: flex;
    flex-direction: column;
  }

  &__dropper {
    align-items: center;
    display: flex;
    gap: 8px;
    color: #0F1B24;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.Colors {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 2px;
  grid-row-gap: 2px;
  margin: 14px 0 0 0;
  overflow-y: auto;
  max-height: 400px;
  position: relative;

  @include scroll();

  & > div {
    height: 40px;
    cursor: pointer;
  }
}

.WorkPanel {
  background: $color_white;
  height: fit-content;
  width: 100%;

  &_actions {
    width: 100%;
    background: $color_white;
    height: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding: 0 32px;
  }

  &_actionsIcon {
    margin: 0 24px 0 0;
    cursor: pointer;
    transition: all .2s;

    &_active {
      path {
        fill: $color_purple_light !important;
      }
    }

    path {
      fill: #848F98;
    }

    &:hover {
      path {
        fill: $color_purple_light;
      }
    }
  }

  &_actionsIconActive {
    path {
      fill: $color_purple_light;
    }
  }

  &_switcher {
    margin: 0 0 0 20px;
  }

  &_switcherLabel {
    color: $color_gray_dark;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 0 0 0 12px;
    user-select: none;
  }

  &_workArea {
    background: aqua;
    height: 100%;
    width: 100%;
  }

  &_workAreaImg {
    width: 100%;
    height: 100%;
    position: relative;

    & img {
      height: 100%;
    }
  }
}

.zoom {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: absolute;
  bottom: 45%;
  right: 12px;
}

.expandToFullScreen {
  position: absolute;
  right: 12px;
  top: 9px;
}

.switchTimeOfDay {
  position: absolute;
  top: 9px;
  right: 64px;
  width: 94px;
}

.color {
  border: 2px solid #cccccc;
  height: 40px;
}

.SelectedColor {
  border: 2px solid #0F1B24;
}

.colorsInside {
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow-y: auto;
  max-height: 628px;

  @include scroll();
}

.partPreset {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  border: 0.5px solid rgba(196, 201, 206, 0.64);

  &__selectHeader {
    border-radius: 8px;
    background: #F1F2F3;
  }

  &__container {
    display: flex;
    gap: 8px;
  }

  &__color {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 61px;
    height: 40px;
    font-size: 24px;
  }

  &__desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "SFProText-Regular", sans-serif;
  }

  &__label {
    color: #848F98;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  &__title {
    color: #0F1B24;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}

.modal {
  &__content {
    display: flex;
    gap: 24px;
    position: relative;
  }
}

.container {
  display: flex;
  gap: 24px;
  position: relative;
}

.stub {
  box-shadow: 0 4px 8px 0 #E5E7E9;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(212, 210, 211, 0.50);
  color: #0F1B24;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  position: absolute;
  height: 100%;
  z-index: 1;
  width: 100%;
}
