@import "src/global/styles/colors";

.PresetsPage {
  &_root {
   width: 100%;
  }

  &_slide {
    width: 1062px;
    height: 664px;
    position: relative;
    box-sizing: border-box;
    padding: 0 20px;

    &__btn {
      width: 255px;
      height: 48px;
      position: absolute;
      left: 50%;
      bottom: 16px;
      transform: translateX(-50%);

      & img {
        object-fit: none !important;
      }
    }

    & img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}
