@import "src/global/styles/colors";

.InteriorCard {
  &_root {
    width: 100%;
    height: 100%;
    min-height: 164px;
    position: relative;
  }

  &_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &_btnWrapper {
    position: absolute;
    top: 6px;
    right: 6px;
    display: flex;

    & button {
      margin: 0 0 0 8px
    }
  }
}