.Upload {
  position: relative;
  border-radius: 0.125rem;
  background: #FFF;
  box-shadow: 0 4px 8px 0 #E5E7E9;
  width: 100%;
  padding: 0.5rem 0 0.5rem 15px;

  &_text {
    display: flex;
    align-items: center;
    gap: 11px;
    color: #0F1B24;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &_icon {
    width: 17px;
    height: 20px;

    & > path {
      fill: #0F1B24;
    }
  }

  &_label {
    display: flex;
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
  }

  &_input {
    position: absolute;
    visibility: hidden;
    top: 0;
    left: 0;
  }
}
