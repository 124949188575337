@font-face {
  font-family: SFProText-Bold;
  src: url('./SFProText-Bold.ttf');
}

@font-face {
  font-family: SFProText-Medium;
  src: url('./SFProText-Medium.ttf');
}

@font-face {
  font-family: SFProText-Regular;
  src: url('./SFProText-Regular.ttf');
}