.input {
  border: none 0 !important;
  border-radius: 8px !important;
  height: 44px !important;
  width: 100% !important;

  &::placeholder {
    color: rgba(15, 27, 36, 0.48);
    font-feature-settings: 'case' on;
    font-family: "Open Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.label {
  cursor: auto;
  color: #0F1B24;
  font-family: "SF-Bold", sans-serif;
  font-size: 13px;
  font-style: normal;
  margin: 0 0 6px 4px;
  font-weight: 700;
  line-height: 19px; /* 146.154% */
}

.error {
  color: #DA0D0D;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;  
}

.inputError {
  border: 1px solid #DA0D0D !important;
}
