@import "src/global/styles/colors";

.Header {
  &_root {
    display: flex;
    justify-content: space-between;
    max-width: 1440px;
    box-sizing: border-box;
    padding: 16px 32px;
    margin: 0 auto 35px;
  }

  &_actions {
    display: flex;
  }

  &_navLinks {
    display: flex;
    margin: 0 72px 0 0;
  }

  &_link {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: $color_gray_dark;
    transition: all .2s;
    display: flex;
    align-items: center;
    opacity: .6;
    margin: 0 0 0 40px;


    & svg {
      margin: 0 8px 0 0;
      path {
        fill: $color_gray_dark;
      }
    }

    &:hover {
      opacity: 1;
      color: $color_purple_light;
      & svg {
        path {
          fill: $color_purple_light;
        }
      }
    }
  }

  &_linkActive {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: $color_gray_dark;
    transition: all .2s;
    display: flex;
    align-items: center;
    margin: 0 0 0 40px;


    & svg {
      margin: 0 8px 0 0;
      path {
        fill: $color_gray_dark;
      }
    }
  }

  &_authPanel {
    display: flex;

    & .Header_link {
      margin: 0;
      opacity: 1;

      &:nth-child(2) {
        margin: 0 0 0 36px;
      }
    }
  }
  
  &_authPanelUser {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    &__avatar {
      width: 40px;
      height: 40px;
      background: $color_white;
      border-radius: 100%;
      filter: drop-shadow(0px 4px 8px #E5E7E9);
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;
    }

    &__name {
      color: #000;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin: 0 8px 0 12px;
      user-select: none;
    }

    &__arrow {
      transform: rotate(180deg);
      user-select: none;
    }

    &__menu {
      border-radius: 8px;
      background: #FFF;
      box-shadow: 0 4px 8px 0 #E5E7E9;
      box-sizing: border-box;
      padding: 16px 20px 4px;
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translateY(calc(100% + 8px));
      display: flex;
      flex-direction: column;
      z-index: 2;

      & > a {
        color: $color_gray_dark;
        font-family: "SFProText-Regular", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0 0 12px 0;
      }
    }
  }
}

.Rotate {
  transform: rotate(360deg);
}