.container {
  display: flex;
  flex-direction: column;
  gap: 6px;

  &_label {
    color: #0f1b24;
    font-family: SFProText-Regular, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    margin: 0 0 0 4px;
  }

  & div[class*='react-select__control'] {
    border: 0 none;
  }

  & div[class*='react-select__placeholder'] {
    color: rgba(15, 27, 36, 0.48);
    font-feature-settings: 'case' on;
    font-family: "Open Sans", serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 8px;
  }

  & div[class*='react-select__input-container'] {
    margin: 8px;
  }

  & div[class*='react-select__single-value'] {
    font-feature-settings: "case" on;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 8px;
  }

  & div[class*='react-select__option'] {
    font-feature-settings: "case" on;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &_error_msg {
    color: #DA0D0D;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &_error {
    & > div[class*='react-select__control'] {
      border: 1px solid #DA0D0D !important;
      border-radius: 8px
    }
  }
}
