@import "src/global/styles/colors";

.ButtonIcon {
  &_root {
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.48);
    backdrop-filter: blur(32px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    transition: all .2s;

    &:hover {
      background: rgba(0, 0, 0, 0.64);
    }

    &:active {
      background: rgba(0, 0, 0, 0.80);
    }

    & img {
      max-width: 16px;
      height: 16px;
    }

    & p {
      color: $color_white;
      font-family: 'Open Sans', sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 0 0 0 12px;
    }
  }
}