.tooltip {
  width: 140px !important;
  height: 46px !important;
  z-index: 99999;

  &_content {
    display: flex;
    flex-direction: column;
  }

  &_config {
    display: flex;
    column-gap: 6px;
    padding-top: 3px;
    padding-bottom: 12px;
  }

  &_tool {
    cursor: pointer;
    min-width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &_toolActive {
    border-radius: 2px;
    background: #FFF;

    svg > path {
      stroke: #0F1B24;;
    }
  }
}
