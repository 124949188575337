.container {
  background: transparent;
  border: 1px solid #848F98;
  display: flex;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 16px;
  }

  svg > path {
    fill: #475569;
  }

  &_active {
    border: 2px solid #0F1B24;
  }
}
