@import "src/global/styles/colors";
@import "src/global/styles/scroll";

.ColourPage {
  &_root {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 24px;
    position: relative;
  }

  &_color {
    width: 100%;
    height: 100%;
    min-height: 473px;
    position: relative;

    &__btn {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }

  &_colorFullScreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 248px);
    z-index: 3;
  }

  &_interior {
    background: $color_white;
    box-shadow: 0 4px 8px 0 #E5E7E9;
    box-sizing: border-box;
    position: relative;
    padding: 0;
    min-height: 480px;

    & img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    &__title {
      width: 100%;
      height: 40px;
      background: $color-white;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color_gray_dark;
      text-align: center;
      font-family: 'Open Sans', sans-serif;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &__btn {
      width: calc(100% - 16px);
      position: absolute;
      left: 8px;
      bottom: 8px;

      & img {
        object-fit: none;
      }
    }
  }

  &_info {
    background: $color_white;
    box-shadow: 0 4px 8px 0 #E5E7E9;
    box-sizing: border-box;
    max-height: 565px;
    overflow-y: auto;
    padding: 16px 16px 60px;
    width: 302px;
    position: relative;
    overflow-x: hidden;
    @include scroll();

    &__item, &__itemCustom {
      box-sizing: border-box;
      padding: 0 0 12px 0;
      border-bottom: 1px solid $color_graywash_800;
      margin: 0 0 8px 0;

      & p {
        color: #0F1B24;
        font-family: 'Open Sans', sans-serif;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0 0 6px 0;
      }

      & span {
        color: $color_gray_dark;
        font-family: 'Open Sans', sans-serif;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: -0.34px;
      }
    }

    &__itemText {
      display: block;
    }

    &__itemCustom {
      border: none;
      margin: 0;
    }
  }

  &_couples {
    background: $color_white;
    box-shadow: 0 4px 8px 0 #E5E7E9;
    box-sizing: border-box;
    padding: 16px;
    position: relative;
    width: 302px;

    &__box {

    }

    &__boxTitle {
      color: $color_graywash_700;
      font-family: 'Open Sans', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0 0 8px 0;
    }

    &__boxColors {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-column-gap: 2px;
      grid-row-gap: 2px;
      margin: 0 0 16px 0;
      grid-template-rows: 34px;
    }

    &__boxColorsItem {
      border: 2px solid #cccccc;
      height: 34px;
    }

    &__boxColorsItemActive {
      border: 2px solid #0F1B24;
    }
  }
}

.textureHeader {
  background: #F1F2F3;
}

.textureName {
  width: 230px;
}

.previewColor {
  position: relative;
  width: 302px;
  height: 565px;
  background: #fff;
  box-shadow: 0 4px 8px 0 #E5E7E9;

  &__img {
    position: absolute;
    height: 100%;
    object-fit: cover;
  }

  &__color {
    height: 100%;
    width: 100%;
  }

  &__icon {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  &__full {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 99999999;
  }
}

.palette {
  width: 302px;
  height: 565px;
  background: #fff;
  box-shadow: 0 4px 8px 0 #E5E7E9;
  box-sizing: border-box;
  padding: 16px;
  position: relative;

  &__section {
    display: flex;
  }

  p {
    color: #0F1B24;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    padding-bottom: 8px;
  }

  &__colors {
    position: relative;
    max-height: 270px;
    overflow-y: auto;
    @include scroll();
  }

  &__link {
    display: block;
    color: #FFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.48);
    backdrop-filter: blur(32px);
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 10px 36px 11px 36px;
    width: 270px;
    position: sticky;
    bottom: -50px;
  }
}

.clearFilters {
  position: absolute;
  right: 16px;
  font-size: 11px;
  font-family: 'Open Sans', sans-serif;
  color: #2424b4;
  font-weight: 600;
}

.OtherColorModal {
  p {
    color: #0F1B24;
    text-align: center;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 140% */
    padding-bottom: 24px;
  }

  &__actions {
    display: flex;
    gap: 8px;
    justify-content: center;
  }

  &__cancel {
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.16);
    color: rgb(15, 27, 36);

    &:hover {
      background: transparent;
    }
  }
}
