@import 'src/global/styles/colors';

.Switcher {
  &_root {}
  &_switch {
    position: relative;
    display: inline-block;
    width: 37px;
    height: 20px;

    & input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    & input:checked + .Switcher_slider {
      background-color: $color_gray_dark;
    }

    & input:focus + .Switcher_slider {
      box-shadow: 0 0 1px $color_gray_dark;
    }

    & input:checked + .Switcher_slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(17px);
    }
  }

  &_slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
  }

  &_round {
    border-radius: 34px;
  }

  &_round:before {
    border-radius: 50%;
  }
}