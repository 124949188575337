@import "src/global/styles/colors";

.Button {
  &_root {
    position: relative;
    border-radius: 8px;
    background: $color_gray_dark;
    color: $color_white;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    box-sizing: border-box;
    padding: 9px 32px;
    transition: all .2s;

    &:hover {
      background: $color_purple_light;
    }

    &:active {
      background: $color_purple_dark;
    }

    &:disabled {
      background: rgba(15, 27, 36, 0.32);
    }
  }
}