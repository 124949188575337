@import "src/global/styles/colors";

.SettingsPage {
  &_root {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 100px 0 0 0;
  }

  &_title {
    color: $color_gray_dark;
    font-family: "Open Sans", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    display: block;
    width: 100%;
    text-align: center;
    margin: 0 0 40px 0;
  }

  &_options {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 406px;

    &__item {
      margin: 12px 0 0 0;
      box-sizing: border-box;
      padding: 0 0 12px 0;
      display: flex;
      justify-content: space-between;
      color: $color_gray_dark;
      font-family: "SFProText-Regular", sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
