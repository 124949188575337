.container {
  background: #fff;
  box-shadow: 0 0 8px 0 rgba(15, 27, 36, 0.32);
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;

  &_open {
    top: 0;
  }

  &_header {
    display: flex;
    justify-content: space-between;
    padding: 10px 16px 10px 17px;
    cursor: pointer;

    &_title {
      font-family: "SFProText-Regular", sans-serif;
      color: #0F1B24;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &_dropdown {
      transition: all .2s;
    }

    &_dropdown_open {
      transform: rotate(180deg);
    }
  }

  &_layers {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  &_layer {
    padding: 12px 0 12px 0;
    color: #0F1B24;
    font-family: "SFProText-Regular", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-top: 0.5px solid rgba(132, 143, 152, 0.40);
    margin-left: 16px;
    margin-right: 16px;
    display: flex;
    justify-content: space-between;

    &:last-child {
      border-bottom: 0.5px solid rgba(132, 143, 152, 0.40);
    }

    &_about {
      display: flex;
      column-gap: 8px;
    }

    &_color {
      border: 1px solid #0F1B24;
      border-radius: 4px;
      height: 16px;
      width: 16px;
    }

    &_actions {
      display: flex;
      column-gap: 16px;
      padding-right: 8px;

      svg {
        cursor: pointer;
        font-size: 16px;

        &:last-child path {
          fill: #0F1B24;
        }
      }
    }
  }

  &_layers_hidden {
    visibility: hidden;
    height: 0;
  }
}
