@import "src/global/styles/colors";
@import "src/global/styles/scroll";

.ColoursPage {
  &_root {
    width: 100%;
  }

  &_filters {
    display: flex;
    justify-content: space-between;
    margin: 0 0 24px 0;

    &__section {
      display: flex;
    }

    &__sectionSelect {
      margin: 0 0 0 16px;
    }
  }

  &_pallete {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    width: 100%;
    max-height: calc(100vh - 126px - 148px);
    overflow-y: auto;
    @include scroll();
  }

  &_item,
  &_itemLigth {
    width: 138px;
    height: 138px;
    box-sizing: border-box;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
    position: relative;

    & span {
      display: block;
      color: $color_gray_dark;
    }

    &__code {
      text-transform: uppercase;
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      user-select: none;
      position: relative;
      z-index: 2;
    }

    &__name {
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      user-select: none;
    }

    &__btn {
      width: auto;
      user-select: none;
      position: absolute;
      bottom: 8px;
      left: 8px;
      z-index: 1;
    }
  }

  &_itemLigth {
    & span {
      color: $color_white;
    }
  }
}

.colorEffect {
  position: absolute;
  width: 100%;
  z-index: 1;
  border: 0 none;
  padding: 0;
  object-fit: cover;
  height: 138px;
}

.emptyColors {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: calc(100vh - 350px);
  justify-content: center;
  margin: 0 auto;

  p {
    color: rgba(71, 82, 90, 0.88);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    padding-bottom: 12px;
  }
}
