.sidebar {
  position: relative;
  height: 100%;

  footer {
    position: absolute;
    bottom: 0;
    background: white;
    display: flex;
    padding: 14px 0 27px 30px;
    border-top: 1px solid rgb(241, 242, 243);
    gap: 12px;
    width: 100%;
  }

  &_footerInfo {
    display: flex;
    flex-direction: column;

    span {
      color: rgb(15, 27, 36);
      font-family: "Open Sans", serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
    }

    span:last-child {
      color: rgb(68, 26, 84);
      font-family: SFProText-Regular, serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      padding-top: 3px;
    }
  }

  footer img {
    background: rgb(68, 26, 84);
    border-radius: 50%;
    height: 36px;
    width: 36px;
  }
}

.logo {
  padding-left: 20px;
}

.navigation {
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  &_link {
    color: rgb(30, 31, 67);
    font-family: "Open Sans", serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    padding: 13px 0 12px 20px;

    &:hover {
      color:rgb(68, 26, 84);
    }
  }

  &_linkActive {
    background: rgb(68, 26, 84);
    color: rgb(255, 255, 255);

    &:hover {
      color: rgb(255, 255, 255);
    }
  }
}
