@import 'src/global/styles/colors';

.Tabs {
  &_root {
    display: flex;
    margin: 0 0 16px 0;
  }

  &_tab, &_active {
    color: $color_graywash_700;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 0 17px 0 0;
    box-sizing: border-box;
    padding: 0 0 6px 0;
    cursor: pointer;
    white-space: nowrap;
  }

  &_active {
    color: $color_gray_dark;
    border-bottom: 2px solid $color_gray_dark;
  }
}