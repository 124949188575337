.header {
  display: flex;
  justify-content: space-between;
  padding-top: 45px;
  align-items: center;
  padding-bottom: 24px;
}

.title {
  color: rgb(15, 27, 36);
  font-family:
    Open Sans,
    serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
}

.actions {
  display: flex;
  gap: 8px;
}

.authHeader {
  align-items: center;
  display: flex;

  &_divider {
    border: 2px solid #D9D9D9;
    height: 33px;
    margin: 0 24px;
  }

  &_title {
    color: #000;
    font-family: "Open Sans", serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}
