@import "src/global/styles/colors";

.MainPage {
  &_root {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 250px);
    align-content: center;
    gap: 24px;
    justify-content: center;
  }

  &_card {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 256px;
    border-radius: 8px;
    background: $color-white;
    transition: all .4s;
    overflow: hidden;
    color: $color_black;
    width: 411px;
    
    &:hover {
      box-shadow: 0 0 8px 0 rgba(15, 27, 36, 0.32);
    }

    &__content {
      flex: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      & img {
        width: 111%;
      }
    }

    &__text {
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'SFProText-Regular', sans-serif;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  &_plusWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: rgba(177, 35, 232, 0.12);
    width: 80px;
    height: 80px;
  }
}

.constuctor {
  background: url("../../assets/images/mainpage_card_stub.png") 0 0 no-repeat;
  background-size: cover;
}

.seasons {
  background: url("../../assets/icons/seasons.svg") center center no-repeat;
  background-size: 108px 108px;
}
